.lesson-index {

  .MuiButtonBase-root {
    border: 2px solid #0a3069;
    //background-color: #FC0;
    border-radius: 3px;
    padding-top: 10px;
    padding-bottom: 10px;
    //margin: 5px 0;
    .MuiAvatar-root {
      background-color: #0a3069;
    }
    .MuiTypography-root {
      font-weight: bold;
      color:#0a3069
    }
  }

  .lesson-thumb {
    flex-basis: 100px;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1f6feb;
    overflow: hidden;
    position: relative;
    margin-right: 20px;
    border-radius: 4px;
    border: 1px solid #1f6feb;
    .lesson-num{
      font-size: 100px;
      color: #FFF;
      font-weight: bold;
    }
  }
  &.lesson-index-small {
    .lesson-thumb {
      flex-basis: 60px;
      width: 60px;
      height: 40px;
      margin-right: 20px;
      border-radius: 4px;
      border: 1px solid #1f6feb;
      .lesson-num{
        font-size: 50px;
        color: #FFF;
        font-weight: bold;
      }
    }
  }
  &.dark {
    .MuiButtonBase-root {
      border: 2px solid #111;
      background-color: #222;
      .MuiAvatar-root {
        background-color: #0a3069;
      }
      .MuiTypography-root {
        color:#DEDEDE
      }
    }
    .lesson-thumb {
      background-color: #323232;
      border: 1px solid #323232;
    }
    //&.lesson-index-small {
    //  .lesson-thumb {
    //    background-color: #323232;
    //  }
    //}
  }
}