.welcome-text{
  padding: 20px;
  //color: #FFF;
  //text-shadow: 1px 1px 2px rgba(0,0,0,.8);
  font-family: Inter, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  h3 {
    margin: 0 0 10px 0;
    padding: 0;
    font-size: 24px;
    font-weight: bold;
  }
  p {
    margin: 0 0 5px;
    font-size:16px;
    font-weight: bold;
  }
}