.glossary-word {
  display: flex;
  justify-content: center;
  table {
    td {
      &:first-child {
        background-color: #FFF;
        border-radius: 3px;
      }
      &:nth-child(2),&:nth-child(3) {
        text-align: center;
      }
      padding:16px;
      //vertical-align: top;

      br + br {
        display: none;
      }
    }
  }
}