.Jumbo{
  height: 400px;
  width: 100%;
  background: url("./assets/hiker-l.jpg") no-repeat 50% 50%;
  &.dark {
    background-color: #222;
    background-image: url("./assets/hiker-dark-l.jpg");
  }
  background-size: contain;
  //background-color: #3d5fc2;
  //padding: 30px;

  position: relative;
  overflow: hidden;
  svg {
    position: absolute;
    width :120%;
    left:-10%;
    top:10%;
  }

}