.Header-Search-Dialog {
  .MuiDialogContent-root {
    //background-color: #323232;
    //.MuiFormLabel-root {
    //  color: #FFF;
    //
    //}
    //.MuiFormControl-root {
    //  border-color: #FFF;
    //}
  }
  .MuiDialog-container {
    align-items: flex-start;
    padding-top: 64px;
  }
}

.search-auto-complete-option {
   padding: 10px 20px;
   margin: 0;
  border-bottom: 1px solid rgba(255,255,255,.1);
  &.selected {
    background-color: rgba(255,255,255,.08);
  }
 }